import {
  withSentryReactRouterV6Routing,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import { createBrowserRouter, Routes } from 'react-router-dom';

export const sentryCreateBrowserRouter =
  wrapCreateBrowserRouter(createBrowserRouter);

const SentryRoutesWrapper = withSentryReactRouterV6Routing(Routes);

function SentryRoutes({ children }: { children: React.ReactNode }) {
  return (
    <SentryRoutesWrapper location={window.location}>
      {children}
    </SentryRoutesWrapper>
  );
}

export default SentryRoutes;
