import { TaxFilingStatusEnum } from '../__generated__/graphql';
import { ExistingFile } from './document.types';

export enum DriverCertification {
  CDL_LICENSE = 'cdl-license',
  HAZMAT_ENDORSEMENT = 'hazamat-endorsement',
  MC_NUMBER = 'mc-number',
  NOTARY = 'notary',
  TSA_CERTIFIED = 'tsa-certified',
  TWIC = 'twic',
  CONSTRUCTION_SITE_PPE = 'construction-site-ppe',
}

type License = {
  expiration?: Date | null;
  driver_license_front_image: ExistingFile;
  driver_license_back_image: ExistingFile;
};

export type DriverSkillInput = {
  id?: string;
  document?: string;
  _destroy?: boolean;
};

export type Driver = {
  id?: string;
  tax_filling_status: TaxFilingStatusEnum | null;
  skills: ExistingFile[];
  license: License | null;
};
