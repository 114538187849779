import i18next from 'i18next';
import { useTranslation as _useTranslation } from 'react-i18next';

import { SupportedLanguage } from '../services/i18n/constants';
import { LocaleNamespaceType } from '../services/i18n/locale.types';

export type TranslateType<T> = <NS extends keyof LocaleNamespaceType>(
  key: keyof LocaleNamespaceType[NS] extends undefined
    ? T
    : keyof LocaleNamespaceType[NS],
  options?: {
    count?: number;
    ns?: NS;
    plural?: boolean;
    replace?: Record<string, string>;
  },
) => string;

type UseTranslationReturn<T> = {
  changeLanguage: (language: SupportedLanguage) => Promise<void>;
  getCurrentLanguage: () => string;
  t: TranslateType<T>;
};

export function useTranslation<
  NS extends keyof LocaleNamespaceType,
  NSValues = LocaleNamespaceType[NS],
>(namespace: NS): UseTranslationReturn<keyof NSValues> {
  const { t } = _useTranslation(namespace);

  async function changeLanguage(language: SupportedLanguage): Promise<void> {
    await i18next.changeLanguage(language).catch(err => {
      // eslint-disable-next-line no-console
      console.error('Error changing language', { err, language });
    });
  }

  function getCurrentLanguage(): string {
    return i18next.language;
  }

  return { t, changeLanguage, getCurrentLanguage };
}
