import Typography from '../typography';

function FormErrorLabel({
  children,
  mt = 'mt-1.5',
}: {
  children: JSX.Element | string | undefined;
  mt?: string;
}) {
  return (
    <Typography size="xs" fontWeight="light" color="red" className={mt}>
      {children}
    </Typography>
  );
}

export default FormErrorLabel;
