import { Checkbox, Label } from '@dispatch-ui/react';

export type ChckboxItemProps = {
  value: string;
  text: string;
  secondaryText?: string;
};

type CheckboxFormInputGroupProps = {
  item: ChckboxItemProps;
  onCheckedChange: (checked: boolean) => void;
  checked: boolean;
};

function CheckboxFormInput({
  item,
  onCheckedChange,
  checked = false,
  ...rest
}: CheckboxFormInputGroupProps): JSX.Element {
  return (
    <div
      {...rest}
      key={item.value}
      className="border-border flex items-center space-x-2 rounded-sm border-2"
    >
      <Checkbox
        data-testid={`${item.value}-checkbox-input`}
        value={item.value}
        id={item.value}
        onCheckedChange={onCheckedChange}
        checked={checked}
        className="ml-3"
      />
      <div className="flex flex-col space-y-2 cursor-pointer w-full">
        <Label
          className={`text-radio-button pt-3 pr-3 w-full cursor-pointer ${item.secondaryText ? '' : 'pb-3'}`}
          htmlFor={item.value}
        >
          {item.text}
        </Label>
        {item.secondaryText && (
          <Label
            className="text-secondary text-xs pb-3 pr-3 w-full cursor-pointer text-gray-500"
            htmlFor={item.value}
          >
            {item.secondaryText}
          </Label>
        )}
      </div>
    </div>
  );
}

export default CheckboxFormInput;
