import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from '../hooks/use-translation';
import { SupportedLanguage } from '../services/i18n/constants';
import LanguageContext from './language.context';

const LanguageApplicationProvider = LanguageContext.Provider;

function LanguageProvider({ children }: { children: ReactNode }) {
  const [currentLanguage, setCurrentLanguage] =
    useState<SupportedLanguage>('en-US');

  const { getCurrentLanguage, changeLanguage: changeLanguageTranslation } =
    useTranslation('common');

  useEffect(() => {
    const lng = getCurrentLanguage();
    setCurrentLanguage(lng as SupportedLanguage);
  }, [getCurrentLanguage]);

  const changeLanguage = (language: SupportedLanguage) => {
    changeLanguageTranslation(language);
    setCurrentLanguage(language);
  };

  return (
    <LanguageApplicationProvider value={{ currentLanguage, changeLanguage }}>
      {children}
    </LanguageApplicationProvider>
  );
}

export default LanguageProvider;
