import { cn } from '@dispatch-ui/utils';
import Vehicle10BoxSvg from '../../../../../assets/images/vehicle-10-box-truck.svg?react';
import Vehicle16BoxSvg from '../../../../../assets/images/vehicle-16-box-truck.svg?react';
import Vehicle20BoxSvg from '../../../../../assets/images/vehicle-20-box-truck.svg?react';
import VehicleCarSvg from '../../../../../assets/images/vehicle-car.svg?react';
import VehicleCargovanHighSvg from '../../../../../assets/images/vehicle-cargo-van-high-top.svg?react';
import VehicleCargovanSvg from '../../../../../assets/images/vehicle-cargo-van.svg?react';
import VehicleMidSizedSvg from '../../../../../assets/images/vehicle-mid-sized.svg?react';
import VehiclePickupTruckSvg from '../../../../../assets/images/vehicle-pickup-track.svg?react';
import { VehicleTypeEnum } from '../../../__generated__/graphql';
import { useTranslation } from '../../../hooks/use-translation';
import { Openings } from '../../../services/i18n/locale.types';
import { getVehicleTypeTranslationKey } from '../../available-openings/utils';

export type VehicleTypeBoxProps = {
  vehicleType: VehicleTypeEnum;
  size?: 'small' | 'medium';
  selected?: boolean;
};

function VehicleTypeBox({
  vehicleType,
  size = 'medium',
  selected = false,
}: VehicleTypeBoxProps): JSX.Element {
  const { t } = useTranslation('openings');

  const getVehicleTypeSvg = (small: boolean) => {
    const props = small ? { width: 75, height: 30 } : {};

    switch (vehicleType) {
      case VehicleTypeEnum.BoxTruck_10:
        return <Vehicle10BoxSvg {...props} />;
      case VehicleTypeEnum.BoxTruck_16:
        return <Vehicle16BoxSvg {...props} />;
      case VehicleTypeEnum.BoxTruck_20:
        return <Vehicle20BoxSvg {...props} />;
      case VehicleTypeEnum.CargoVanHighTop:
        return <VehicleCargovanHighSvg {...props} />;
      case VehicleTypeEnum.Car:
        return <VehicleCarSvg {...props} />;
      case VehicleTypeEnum.MidSized:
        return <VehicleMidSizedSvg {...props} />;
      case VehicleTypeEnum.PickupTruck:
        return <VehiclePickupTruckSvg {...props} />;
      default:
        return <VehicleCargovanSvg {...props} />;
    }
  };

  const borderStyle = selected ? 'border-primary border-4' : 'border-border';

  const dimensionStyle =
    size === 'small' ? 'h-[87px] w-[97.8px]' : 'h-[145px] w-[163px]';

  const classes = cn(
    'flex cursor-pointer flex-col items-center justify-center space-y-2 rounded-sm border-2 p-3 uppercase',
    borderStyle,
    dimensionStyle,
  );

  const textStyle = size === 'small' ? 'text-xs' : 'text-base';
  const classesText = cn('text-center', textStyle);

  return (
    <div className={classes}>
      <div>{getVehicleTypeSvg(size === 'small')}</div>
      <div className={classesText}>
        {t(getVehicleTypeTranslationKey(vehicleType) as keyof Openings)}
      </div>
    </div>
  );
}

export default VehicleTypeBox;
