/* eslint-disable no-console */
import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE, supportedLanguages } from './constants';
import { translationNamespaces } from './locale.types';

const commonConfig: InitOptions = {
  ns: translationNamespaces,
  supportedLngs: supportedLanguages,
  fallbackLng: DEFAULT_LANGUAGE,
  fallbackNS: DEFAULT_LANGUAGE,
  // keySeparator: false,
  /* interpolation: {
    escapeValue: false,
  }, */
  defaultNS: DEFAULT_LANGUAGE,
};

async function initI18nWithReact(): Promise<void> {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      ...commonConfig,
      backend: {
        // a Time was added to the end of the URL to avoid caching
        loadPath: `/locales/{{lng}}/{{ns}}.json?cb=${new Date().getTime()}`,
      },
      missingKeyHandler(lngs, ns, key, fallbackValue, updateMissing, options) {
        console.error('Missing translation key', {
          lngs,
          ns,
          key,
          fallbackValue,
          updateMissing,
          options,
        });
      },
    })
    .catch(err => {
      console.error('An error occurred while initializing i18n', err);
    });
}

export async function initI18nForTests(): Promise<void> {
  await i18next
    .use(initReactI18next)
    .init({
      ...commonConfig,
      resources: {
        'es-US': {},
      },
      saveMissing: false,
    })
    .catch(err => {
      console.error('An error occurred while initializing i18n', err);
    });
}

export default initI18nWithReact;
