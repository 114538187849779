import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

declare const window: {
  SENTRY_ENV: string;
} & Window;

async function initSentry() {
  const sentryDsn =
    'https://c918db48a71173694f0e5bf4a8a710c8@o358133.ingest.us.sentry.io/4507260976234496';

  if (!sentryDsn || window.SENTRY_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.info('Sentry is disabled');
    return;
  }

  // eslint-disable-next-line no-console
  console.info('Initializing Sentry');

  Sentry.init({
    attachStacktrace: true,
    dsn: sentryDsn,
    environment: window.SENTRY_ENV || '-',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}

initSentry();
