const API_BASE_URL: string = '';
const API_RELATIVE_PATH = '/api/v1';
const DISPATCH_PRIVACY_POLICY_URL = 'https://www.dispatchit.com/privacy-policy';
const DISPATCH_HARASSMENT_POLICY_URL =
  'https://cdn.dispatchit.com/assets/dispatch_harassment_policy_10_30_2020-e9cf8ade1dcb5ba38dcb04e7c25868a6271b21ff96980bbb99fa94289f0340fd.pdf';
const DISPATCH_PLATFORM_PROVIDER_AGREEMENT_URL =
  'https://cdn.dispatchit.com/assets/driver_terms_of_use_v3_06_30_2023-e80a175e67e58067ca8f4ec203dd04bc84a6946e3973fffe35d49761dd20f737.pdf';
const DOCUSIGN_INTEGRATION_KEY: string =
  import.meta.env.DOCUSIGN_INTEGRATION_KEY || '';
const DOCUSIGN_PAIC = 'paic';
const DOCUSIGN_CONTRACTOR_AGREEMENTS = 'contractor-agreements';
const EMAIL_DRIVER_CARE = 'drivercare@dispatchit.com';
const S3_PRESIGNED_URL = '/direct_uploads';

export {
  API_BASE_URL,
  API_RELATIVE_PATH,
  DISPATCH_HARASSMENT_POLICY_URL,
  DISPATCH_PLATFORM_PROVIDER_AGREEMENT_URL,
  DISPATCH_PRIVACY_POLICY_URL,
  DOCUSIGN_CONTRACTOR_AGREEMENTS,
  DOCUSIGN_INTEGRATION_KEY,
  DOCUSIGN_PAIC,
  EMAIL_DRIVER_CARE,
  S3_PRESIGNED_URL,
};
