/* eslint-disable @typescript-eslint/no-unused-vars */
import { captureException, ErrorBoundary } from '@sentry/react';
import { useEffect } from 'react';

function RedirectTo404() {
  useEffect(() => {
    window.location.href = '/404.html';
  }, []);
  return <div />;
}

function RedirectTo500() {
  useEffect(() => {
    window.location.href = '/500.html';
  }, []);
  return <div />;
}

function CaptureException(err: Error) {
  captureException(err);
}

function SentryErrorBoundary({ children }: { children: React.ReactNode }) {
  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export { CaptureException, RedirectTo404, RedirectTo500 };

export default SentryErrorBoundary;
