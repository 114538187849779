import { VehicleTypeEnum } from '../../../__generated__/graphql';

const VEHICLE_TYPES_SORTED_BY_SIZE = [
  VehicleTypeEnum.Car,
  VehicleTypeEnum.MidSized,
  VehicleTypeEnum.PickupTruck,
  VehicleTypeEnum.CargoVan,
  VehicleTypeEnum.CargoVanHighTop,
  VehicleTypeEnum.BoxTruck_10,
  VehicleTypeEnum.BoxTruck_16,
  VehicleTypeEnum.BoxTruck_20,
];

export default VEHICLE_TYPES_SORTED_BY_SIZE;
