import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@dispatch-ui/react';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export type PaginationArgs = {
  first: number | undefined;
  last: number | undefined;
  after: string | undefined | null;
  before: string | undefined | null;
};

export const PAGINATION_INITIAL_ARGS: PaginationArgs = {
  first: DEFAULT_ITEMS_PER_PAGE,
  last: undefined,
  after: '',
  before: '',
};

export type PaginationComponentProps = {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  handleFirstPage: () => void;
};

export default function PaginationComponent({
  hasPreviousPage,
  hasNextPage,
  handleNextPage,
  handlePreviousPage,
  handleFirstPage,
}: PaginationComponentProps) {
  return (
    <div>
      <Pagination>
        <PaginationContent>
          {
            // Previous page button
            hasPreviousPage && (
              <PaginationLink
                aria-label="Go to first page"
                size="default"
                className="gap-1 pr-2.5 cursor-pointer"
                onClick={handleFirstPage}
              >
                <span>First</span>
              </PaginationLink>
            )
          }
          {
            // Previous page button
            hasPreviousPage && (
              <PaginationItem className="cursor-pointer">
                <PaginationPrevious onClick={handlePreviousPage} />
              </PaginationItem>
            )
          }
          {
            // Next page button
            hasNextPage && (
              <PaginationItem className="cursor-pointer">
                <PaginationNext onClick={handleNextPage} />
              </PaginationItem>
            )
          }
        </PaginationContent>
      </Pagination>
    </div>
  );
}
