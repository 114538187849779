export interface LocaleNamespaceType {
  'application-status': ApplicationStatus;
  common: Common;
  'driver-certifications': DriverCertifications;
  'driver-details': DriverDetails;
  'driver-skills': DriverSkills;
  'join-our-network': JoinOurNetwork;
  'multiple-applications': MultipleApplications;
  openings: Openings;
  'personal-info': PersonalInfo;
  'review-and-sign': ReviewAndSign;
  'sign-in': SignIn;
  'vehicle-details': VehicleDetails;
}

export interface ApplicationStatus {
  ALREADY_APPLIED_TITLE: string;
  ALREADY_APPLIED_DESC: string;
  ALREADY_USER_TITLE: string;
  ALREADY_USER_DESC: string;
  APPLICATION_SENT_DESC: string;
  APPLICATION_SENT_TITLE: string;
  REJECTED_APPLICANT_DOES_NOT_MEET_REQUIREMENTS_DESC: string;
  REJECTED_APPLICANT_DOES_NOT_MEET_REQUIREMENTS_TITLE: string;
  REJECTED_APPLICANT_INVALID_DOCUMENTATION_DESC: string;
  REJECTED_APPLICANT_INVALID_DOCUMENTATION_TITLE: string;
  REJECTED_APPLICANTS_DRIVER_ADDRESS_OUTSIDE_OPENING_STATE_DESC: string;
  REJECTED_APPLICANTS_DRIVER_ADDRESS_OUTSIDE_OPENING_STATE_TITLE: string;
  REJECTED_APPLICANTS_VEHICLE_TYPE_NOT_NEEDED_DESC: string;
  REJECTED_APPLICANTS_VEHICLE_TYPE_NOT_NEEDED_TITLE: string;
  REJECTED_BACKGROUND_CHECK_FAIL_DESC: string;
  REJECTED_BACKGROUND_CHECK_FAIL_TITLE: string;
  REJECTED_INACTIVE_APPLICATION_DESC: string;
  REJECTED_INACTIVE_APPLICATION_TITLE: string;
  REJECTED_OPENING_DEACTIVATED_DESC: string;
  REJECTED_OPENING_DEACTIVATED_TITLE: string;
  REJECTED_OTHER_DESC: string;
  REJECTED_OTHER_TITLE: string;
  REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_DESC: string;
  REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_DESC_ITEM_1: string;
  REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_DESC_ITEM_2: string;
  REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_DESC_ITEM_3: string;
  REJECTED_PERSONAL_INFO_NOT_MEET_REQUIREMENTS_TITLE: string;
  REJECTED_VEHICLE_TYPE_MODIFIED_DESC: string;
  REJECTED_VEHICLE_TYPE_MODIFIED_TITLE: string;
}
export interface Common {
  BACK: string;
  CANCEL: string;
  CLOSE: string;
  ERROR_FILES_EXCEED_SIZE: string;
  ERROR_FILES_INVALID_FORMAT: string;
  ERROR_FILES_MAX_NUMBER_FILES: string;
  ERROR_FILES: string;
  FINISH: string;
  NEXT: string;
  NO: string;
  REQUIRED: string;
  REQUIRED_FIELD: string;
  REQUIRED_FILE: string;
  REQUIRED_FILE_MULTIPLE: string;
  REQUIRED_FIX: string;
  REQUIRED_OPTION: string;
  REQUIRED_SIGNING: string;
  RESUME: string;
  SIGNED: string;
  UPLOAD_PHOTO_RESTRICTIONS: string;
  UPLOAD_PHOTO_RESTRICTIONS_ONE_FILE: string;
  UPLOAD_PHOTO: string;
  VIEW: string;
  YES: string;
}

export interface DriverCertifications {
  DESCRIPTION_A: string;
  DESCRIPTION_B_1: string;
  DESCRIPTION_B_2: string;
  DESCRIPTION_B_3: string;
  DESCRIPTION_B_4: string;
  DESCRIPTION_B_5: string;
  DESCRIPTION_B_6: string;
  DESCRIPTION_B: string;
  DESCRIPTION_C: string;
  IFRAME_TAKES_FEW_SECONDS: string;
  PAGE_TITLE: string;
}

export interface DriverDetails {
  ADDRESS_LABEL: string;
  APT_OR_SUITE_LABEL: string;
  CITY_LABEL: string;
  DOCUMENT_DRIVER_LICENSE_BACK_DESC: string;
  DOCUMENT_DRIVER_LICENSE_BACK_TITLE: string;
  DOCUMENT_DRIVER_LICENSE_FRONT_DESC: string;
  DOCUMENT_DRIVER_LICENSE_FRONT_TITLE: string;
  DOCUMENT_DRIVER_LICENSE_TITLE: string;
  DOCUMENT_PROFILE_PHOTO_DESC: string;
  DOCUMENT_PROFILE_PHOTO_TITLE: string;
  DRIVER_LICENSE_EXPIRATION: string;
  PAGE_DESCRIPTION: string;
  PAGE_TITLE: string;
  STATE_LABEL: string;
  TAX_FILING_STATUS_LABEL: string;
  ZIP_CODE_LABEL: string;
}

export interface PersonalInfo {
  AGE_RANGE_LABEL: string;
  AGE_RANGE_LABEL2: string;
  AGREEMENTS_REQUIRED: string;
  CONTACT_BY_PHONE_NUMBER_ACCEPTED_LABEL: string;
  DOCUMENTS_DISPATCH_HARASSMENT_POLICY: string;
  DOCUMENTS_DISPATCH_PRIVACY_POLICY: string;
  DOCUMENTS_DISPATCH_PLATFORM_PROVIDER_AGREEMENT: string;
  EMAIL_LABEL: string;
  FIRST_NAME_LABEL: string;
  LAST_NAME_LABEL: string;
  LICENSED_TWO_YEARS_OR_MORE_LABEL: string;
  PAGE_TITLE: string;
  PHONE_NUMBER_LABEL: string;
  REQUIRED_EMAIL: string;
  REQUIRED_PHONE_NUMBER: string;
  TERMS_AND_CONDITIONS_ACCEPTED_LABEL: string;
  VEHICLE_CURRENTLY_INSURED_LABEL: string;
  VEHICLE_CURRENTLY_INSURED_LABEL2: string;
}

export interface DriverSkills {
  DRIVER_CERTIFICATIONS_OPTION_CDL: string;
  DRIVER_CERTIFICATIONS_OPTION_CONSTRUCTION_DESC: string;
  DRIVER_CERTIFICATIONS_OPTION_CONSTRUCTION: string;
  DRIVER_CERTIFICATIONS_OPTION_HAZMAT: string;
  DRIVER_CERTIFICATIONS_OPTION_MC_NUMBER: string;
  DRIVER_CERTIFICATIONS_OPTION_NOTARY: string;
  DRIVER_CERTIFICATIONS_OPTION_TSA_CERTIFIED: string;
  DRIVER_CERTIFICATIONS_OPTION_TWIC: string;
  DRIVER_CERTIFICATIONS_SUBTITTLE: string;
  DRIVER_CERTIFICATIONS_TITTLE: string;
  PAGE_TITLE: string;
  SELECT_VEHICLE_TYPE: string;
  VEHICLE_FEATURE_OPTION_CARGO_BARRIER: string;
  VEHICLE_FEATURE_OPTION_DOLLY: string;
  VEHICLE_FEATURE_OPTION_LADDER_RACK: string;
  VEHICLE_FEATURE_OPTION_LIFE_GATE: string;
  VEHICLE_FEATURE_OPTION_PALLET_JACK: string;
  VEHICLE_FEATURE_OPTION_PIPE_RACK: string;
  VEHICLE_FEATURE_OPTION_RAMP: string;
  VEHICLE_FEATURE_OPTION_ROOF_RACK: string;
  VEHICLE_FEATURE_SUBTITTLE: string;
  VEHICLE_FEATURE_TITTLE: string;
}

export interface JoinOurNetwork {
  ABOUT_THE_APPLICATION_DESCRIPTION: string;
  ABOUT_THE_APPLICATION_ITEM1: string;
  ABOUT_THE_APPLICATION_ITEM2: string;
  ABOUT_THE_APPLICATION_ITEM3: string;
  ABOUT_THE_APPLICATION_NOTE: string;
  ABOUT_THE_APPLICATION_TITLE: string;
  ABOUT_THE_JOB_DESCRIPTION: string;
  ABOUT_THE_JOB_TITLE: string;
  BEFORE_GET_STARTED_DESCRIPTION: string;
  BEFORE_GET_STARTED_ITEM1: string;
  BEFORE_GET_STARTED_ITEM2: string;
  BEFORE_GET_STARTED_ITEM3: string;
  BEFORE_GET_STARTED_ITEM4: string;
  BEFORE_GET_STARTED_ITEM5: string;
  BEFORE_GET_STARTED_ITEM6: string;
  BEFORE_GET_STARTED_ITEM7: string;
  BEFORE_GET_STARTED_ITEM8: string;
  BEFORE_GET_STARTED_TITLE: string;
  PAGE_TITLE: string;
  START_APPLICATION_BUTTON: string;
}

export interface MultipleApplications {
  ADMIN_APPLICANT_ALREADY_DRIVER: string;
  ADMIN_BACKGROUND_CHECK_FAIL: string;
  ADMIN_OTHER_POSITIONS_REJECTED: string;
  ADMIN_THERE_ARE_APPLICATIONS_FOR_OTHER_POSITIONS: string;
  ALREADY_APPLIED: string;
  ALREADY_DRIVER: string;
  BACKGROUND_CHECK_FAIL: string;
  CAN_ONLY_APPLY_FOR_ONE_POSITION: string;
  IDENTITY_VERIFICATION_BACKGROUND_CHECK_FAIL: string;
  OTHER_POSITIONS_REJECTED: string;
  THERE_ARE_APPLICATIONS_FOR_OTHER_POSITIONS: string;
}

export interface Openings {
  AVAILABLE_OPENINGS_TITLE: string;
  DONT_SEE_OPPORTUNITIES: string;
  NO_OPENINGS_MATCH_DESCRIPTION: string;
  NO_OPENINGS_MATCH_TITLE: string;
  OPENINGS_PAGE_TITLE: string;
  SUBTITLE1: string;
  SUBTITLE2: string;
  TEXT_SEARCH_LABEL: string;
  TEXT_SEARCH_PLACEHOLDER: string;
  TITLE: string;
  VEHICLE_TYPE_BOX_TRUCK_10: string;
  VEHICLE_TYPE_BOX_TRUCK_16: string;
  VEHICLE_TYPE_BOX_TRUCK_20: string;
  VEHICLE_TYPE_CAR: string;
  VEHICLE_TYPE_CARGO_VAN_HIGH_TOP: string;
  VEHICLE_TYPE_CARGO_VAN: string;
  VEHICLE_TYPE_MID_SIZED: string;
  VEHICLE_TYPE_PICKUP_TRUCK: string;
}

export interface ReviewAndSign {
  CONTRACTORS_AGREEMENTS_PROVIDER_AGREEMENT_DESC: string;
  CONTRACTORS_AGREEMENTS_PROVIDER_AGREEMENT: string;
  IFRAME_TAKES_FEW_SECONDS: string;
  PAGE_DESCRIPTION: string;
  PAGE_TITLE: string;
  PAIC_DOCUMENTS_DESC: string;
  PAIC_DOCUMENTS: string;
  REVIEW_AND_SIGN_ACTION: string;
}

export interface SignIn {
  CHECK_YOUR_EMAIL: string;
  CHECK_YOUR_EMAIL_DESC: string;
  CODE: string;
  CODE_REQUIRED: string;
  CONTINUE: string;
  EMAIL_LABEL: string;
  EMAIL_NOT_VALID: string;
  EMAIL_REQUIRED: string;
  GO_HOMEPAGE: string;
  INVALID_CODE: string;
  LOGOUT: string;
  SIGN_IN_DESC: string;
  SIGN_IN_TITLE: string;
  SIGN_IN: string;
  SIGNED_IN: string;
  SIGNED_IN_AS: string;
  VERIFY_CODE: string;
}

export interface VehicleDetails {
  DRIVER_SKILLS_DRIVER_CERTIFICATION_DESC: string;
  DRIVER_SKILLS_DRIVER_CERTIFICATION_LABEL: string;
  INSURANCE_EXPIRATION_LABEL: string;
  INSURANCE_TYPE_LABEL: string;
  LICENSE_PLATE_EXPIRATION_LABEL: string;
  LICENSE_PLATE_NUMBER_LABEL: string;
  LICENSE_PLATE_STATE_LABEL: string;
  MAKE_LABEL: string;
  MODEL_LABEL: string;
  PAGE_DESCRIPTION: string;
  PAGE_TITLE: string;
  PHOTO_VEHICLE_PHOTO_DESC: string;
  PHOTO_VEHICLE_PHOTO_LABEL: string;
  PHOTOS_DOCUMENTATION_TITLE: string;
  PROOF_OF_INSURANCE_DESC: string;
  PROOF_OF_INSURANCE_LABEL: string;
  VIN_LABEL: string;
}

export const translationNamespaces = [
  'application-status',
  'common',
  'driver-certifications',
  'driver-details',
  'driver-skills',
  'join-our-network',
  'multiple-applications',
  'openings',
  'personal-info',
  'review-and-sign',
  'sign-in',
  'vehicle-details',
] as const;
