import { Analytics } from '@dispatch/analytics';
import posthogPlugin from '@dispatch/analytics-plugin-posthog-js';

const analytics = Analytics({
  app: 'driver-onboarding',
  plugins: [
    posthogPlugin({
      token: window.POSTHOG_API_KEY,
      enabled: true,
      options: {},
    }),
  ],
});

export default { analytics };
