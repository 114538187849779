/* eslint-disable jsx-a11y/label-has-associated-control */
import { cn } from '@dispatch-ui/utils';

type TypographyProps = {
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl';
  fontWeight?:
    | 'thin'
    | 'extralight'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semibold'
    | 'bold'
    | 'extrabold'
    | 'black';
  color?:
    | 'primary'
    | 'secondary'
    | 'black'
    | 'red'
    | 'gray-600'
    | 'gray-500'
    | 'gray-400'
    | 'white'
    | 'destructive';
};

function Typography(
  props: TypographyProps &
    React.ComponentProps<'label'> &
    React.ComponentProps<'div'>,
) {
  const {
    children,
    size,
    fontWeight = 'normal',
    color,
    className,
    ...rest
  } = props;

  const colorStyle = !!color && `text-${color}`;
  const sizeStyle = !!size && `text-${size}`;
  const fontWeightStyle = !!fontWeight && `font-${fontWeight}`;
  const textSpacingStyle = size === '2xl' ? 'tracking-wider' : 'tracking-wide';

  const classes = cn(
    className,
    fontWeightStyle,
    textSpacingStyle,
    sizeStyle,
    colorStyle,
  );

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
}

export default Typography;
