import { VehicleTypeEnum } from '../../__generated__/graphql';
import { DriverSkills, Openings } from '../../services/i18n/locale.types';
import { DriverCertification } from '../../types/driver.types';
import { VehicleFeature } from '../../types/vehicle.types';

function getVehicleTypeTranslationKey(
  vehicleType: VehicleTypeEnum,
): keyof Openings {
  switch (vehicleType) {
    case VehicleTypeEnum.Car:
      return 'VEHICLE_TYPE_CAR';
    case VehicleTypeEnum.MidSized:
      return 'VEHICLE_TYPE_MID_SIZED';
    case VehicleTypeEnum.PickupTruck:
      return 'VEHICLE_TYPE_PICKUP_TRUCK';
    case VehicleTypeEnum.CargoVan:
      return 'VEHICLE_TYPE_CARGO_VAN';
    case VehicleTypeEnum.CargoVanHighTop:
      return 'VEHICLE_TYPE_CARGO_VAN_HIGH_TOP';
    case VehicleTypeEnum.BoxTruck_10:
      return 'VEHICLE_TYPE_BOX_TRUCK_10';
    case VehicleTypeEnum.BoxTruck_16:
      return 'VEHICLE_TYPE_BOX_TRUCK_16';
    case VehicleTypeEnum.BoxTruck_20:
      return 'VEHICLE_TYPE_BOX_TRUCK_20';
    default:
      return 'VEHICLE_TYPE_CAR';
  }
}

function getVehicleFeatureTranslationKey(
  vehicleFeature: VehicleFeature,
): keyof DriverSkills {
  switch (vehicleFeature) {
    case VehicleFeature.CARGO_BARRIER:
      return 'VEHICLE_FEATURE_OPTION_CARGO_BARRIER';
    case VehicleFeature.DOLLY:
      return 'VEHICLE_FEATURE_OPTION_DOLLY';
    case VehicleFeature.LADDER_RACK:
      return 'VEHICLE_FEATURE_OPTION_LADDER_RACK';
    case VehicleFeature.LIFE_GATE:
      return 'VEHICLE_FEATURE_OPTION_LIFE_GATE';
    case VehicleFeature.PALLET_JACK:
      return 'VEHICLE_FEATURE_OPTION_PALLET_JACK';
    case VehicleFeature.PIPE_RACK:
      return 'VEHICLE_FEATURE_OPTION_PIPE_RACK';
    case VehicleFeature.RAMP:
      return 'VEHICLE_FEATURE_OPTION_RAMP';
    case VehicleFeature.ROOF_RACK:
      return 'VEHICLE_FEATURE_OPTION_ROOF_RACK';
    default:
      return 'VEHICLE_FEATURE_OPTION_DOLLY';
  }
}

function getDriverCertificationTranslationKey(
  driverCertification: DriverCertification,
): { text: keyof DriverSkills; secondaryText?: keyof DriverSkills } {
  switch (driverCertification) {
    case DriverCertification.CDL_LICENSE:
      return { text: 'DRIVER_CERTIFICATIONS_OPTION_CDL' };
    case DriverCertification.CONSTRUCTION_SITE_PPE:
      return {
        text: 'DRIVER_CERTIFICATIONS_OPTION_CONSTRUCTION',
        secondaryText: 'DRIVER_CERTIFICATIONS_OPTION_CONSTRUCTION_DESC',
      };
    case DriverCertification.HAZMAT_ENDORSEMENT:
      return { text: 'DRIVER_CERTIFICATIONS_OPTION_HAZMAT' };
    case DriverCertification.MC_NUMBER:
      return { text: 'DRIVER_CERTIFICATIONS_OPTION_MC_NUMBER' };
    case DriverCertification.NOTARY:
      return { text: 'DRIVER_CERTIFICATIONS_OPTION_NOTARY' };
    case DriverCertification.TSA_CERTIFIED:
      return { text: 'DRIVER_CERTIFICATIONS_OPTION_TSA_CERTIFIED' };
    case DriverCertification.TWIC:
      return { text: 'DRIVER_CERTIFICATIONS_OPTION_TWIC' };
    default:
      return { text: 'DRIVER_CERTIFICATIONS_OPTION_CDL' };
  }
}

export {
  getDriverCertificationTranslationKey,
  getVehicleFeatureTranslationKey,
  getVehicleTypeTranslationKey,
};
