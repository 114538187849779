import { createContext } from 'react';
import { SupportedLanguage } from '../services/i18n/constants';

interface ILanguageContext {
  currentLanguage: string;
  changeLanguage: (language: SupportedLanguage) => void;
}

const LanguageContext = createContext<ILanguageContext>({
  currentLanguage: 'en-Us',
  changeLanguage: () => {},
});

export default LanguageContext;
